var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "basic-container",
        [
          _c(
            "avue-crud",
            {
              ref: "crud",
              attrs: {
                option: _vm.tableOption,
                data: _vm.tableData,
                page: _vm.page
              },
              on: {
                "update:page": function($event) {
                  _vm.page = $event
                },
                "current-change": _vm.currentChange,
                "size-change": _vm.sizeChange,
                "selection-change": _vm.selectionChange,
                "refresh-change": _vm.refreshChange,
                "row-save": _vm.handleSave,
                "row-update": _vm.handleUpdate,
                "row-del": _vm.handleDel
              },
              scopedSlots: _vm._u([
                {
                  key: "status",
                  fn: function(scope) {
                    return [
                      _c("el-switch", {
                        attrs: { "inactive-value": 1, "active-value": 0 },
                        on: {
                          change: function($event) {
                            return _vm.change(scope.row)
                          }
                        },
                        model: {
                          value: scope.row.status,
                          callback: function($$v) {
                            _vm.$set(scope.row, "status", $$v)
                          },
                          expression: "scope.row.status"
                        }
                      })
                    ]
                  }
                }
              ])
            },
            [
              _c(
                "template",
                { slot: "menuRight" },
                [
                  _c("el-date-picker", {
                    staticStyle: {
                      width: "230px !important",
                      "margin-right": "5px"
                    },
                    attrs: {
                      type: "datetime",
                      "value-format": "yyyy-MM-dd HH:mm",
                      format: "yyyy-MM-dd HH:mm",
                      size: "small",
                      placeholder: "根据发起时间筛选(开始时间)"
                    },
                    model: {
                      value: _vm.startDate,
                      callback: function($$v) {
                        _vm.startDate = $$v
                      },
                      expression: "startDate"
                    }
                  }),
                  _c("el-date-picker", {
                    staticStyle: {
                      width: "230px !important",
                      "margin-right": "5px"
                    },
                    attrs: {
                      type: "datetime",
                      "value-format": "yyyy-MM-dd HH:mm",
                      format: "yyyy-MM-dd HH:mm",
                      size: "small",
                      placeholder: "根据发起时间筛选(结束时间)"
                    },
                    model: {
                      value: _vm.endDate,
                      callback: function($$v) {
                        _vm.endDate = $$v
                      },
                      expression: "endDate"
                    }
                  }),
                  _c(
                    "el-select",
                    {
                      staticStyle: {
                        width: "140px !important",
                        marginRight: "5px"
                      },
                      attrs: {
                        clearable: "",
                        placeholder: "故障类型",
                        size: "small"
                      },
                      on: { change: _vm.getFaultTypeId },
                      model: {
                        value: _vm.faultType,
                        callback: function($$v) {
                          _vm.faultType = $$v
                        },
                        expression: "faultType"
                      }
                    },
                    _vm._l(_vm.FaultTypeAllList, function(item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.name, value: item.id }
                      })
                    }),
                    1
                  ),
                  _c(
                    "el-select",
                    {
                      staticStyle: {
                        width: "140px !important",
                        marginRight: "5px"
                      },
                      attrs: {
                        clearable: "",
                        placeholder: "故障代码",
                        size: "small"
                      },
                      model: {
                        value: _vm.faultCode,
                        callback: function($$v) {
                          _vm.faultCode = $$v
                        },
                        expression: "faultCode"
                      }
                    },
                    _vm._l(_vm.faultCodeList, function(item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.code, value: item.id }
                      })
                    }),
                    1
                  ),
                  _c("el-input", {
                    staticStyle: {
                      display: "inlie-block",
                      width: "180px",
                      marginRight: "5px"
                    },
                    attrs: {
                      placeholder: _vm.$t("EQUIPMENT NAME OR CODE"),
                      size: "small"
                    },
                    model: {
                      value: _vm.nameOrCode,
                      callback: function($$v) {
                        _vm.nameOrCode = $$v
                      },
                      expression: "nameOrCode"
                    }
                  }),
                  _c(
                    "el-select",
                    {
                      staticStyle: {
                        width: "110px !important",
                        marginRight: "5px"
                      },
                      attrs: {
                        clearable: "",
                        placeholder: "状态",
                        size: "small"
                      },
                      model: {
                        value: _vm.status,
                        callback: function($$v) {
                          _vm.status = $$v
                        },
                        expression: "status"
                      }
                    },
                    [
                      _c("el-option", {
                        attrs: { label: "待处置", value: "0" }
                      }),
                      _c("el-option", {
                        attrs: { label: "待确认", value: "1" }
                      }),
                      _c("el-option", {
                        attrs: { label: "已确认", value: "2" }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }