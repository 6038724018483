<template>
    <div>
        <basic-container>
            <avue-crud :option="tableOption"
                       :data="tableData"
                       :page.sync="page"
                       ref="crud"
                       @current-change="currentChange"
                       @size-change="sizeChange"
                       @selection-change="selectionChange"
                       @refresh-change="refreshChange"
                       @row-save="handleSave"
                       @row-update="handleUpdate"
                       @row-del="handleDel">
                <template slot="status" slot-scope="scope">
                    <el-switch v-model="scope.row.status" :inactive-value="1" :active-value="0"
                               @change="change(scope.row)">
                    </el-switch>
                </template>
                <!--        <template slot="menuLeft">
                          <importBtn
                              :outApi="this.proApis.FAULTEXPORT"
                              :innerAPI="this.proApis.FAULTIMPORT"
                              :API="this.proApis.FAULTIMPORTTEMPLATE"
                              @refresh="refreshData"
                          ></importBtn>
                        </template>-->
                <template slot="menuRight">
                    <el-date-picker
                            v-model="startDate"
                            type="datetime"
                            value-format="yyyy-MM-dd HH:mm"
                            format="yyyy-MM-dd HH:mm"
                            size="small"
                            style="width:230px !important;margin-right: 5px;"
                            placeholder="根据发起时间筛选(开始时间)">
                    </el-date-picker>
                    <el-date-picker
                            v-model="endDate"
                            type="datetime"
                            value-format="yyyy-MM-dd HH:mm"
                            format="yyyy-MM-dd HH:mm"
                            size="small"
                            style="width:230px !important;margin-right: 5px;"
                            placeholder="根据发起时间筛选(结束时间)">
                    </el-date-picker>
                    <el-select clearable v-model="faultType" placeholder="故障类型" @change="getFaultTypeId" size="small"
                               style="width:140px !important;marginRight: 5px;">
                        <el-option v-for="item in FaultTypeAllList" :key="item.id" :label="item.name"
                                   :value="item.id"></el-option>
                    </el-select>
                    <el-select clearable v-model="faultCode" placeholder="故障代码" size="small"
                               style="width:140px !important;marginRight: 5px;">
                        <el-option v-for="item in faultCodeList " :key="item.id" :label="item.code"
                                   :value="item.id"></el-option>
                    </el-select>
                    <el-input v-model="nameOrCode" :placeholder="$t('EQUIPMENT NAME OR CODE')"
                              style="display:inlie-block;width:180px;marginRight: 5px;" size="small"></el-input>
                    <el-select clearable v-model="status" placeholder="状态" size="small"
                               style="width:110px !important;marginRight: 5px;">
                        <el-option label="待处置" value=0></el-option>
                        <el-option label="待确认" value=1></el-option>
                        <el-option label="已确认" value=2></el-option>
                    </el-select>
                </template>

                <!-- <template slot="menu">
                    <el-button type="text" size="mini" icon="el-icon-discover" style="color:red;">{{$t('DISABLE')}}</el-button>
                </template> -->
            </avue-crud>
        </basic-container>
    </div>
</template>
<script>
    import {list, add, edit, remove} from "@/api/warnList"
    import importBtn from "@/components/importExport/importBtn";
    import {getMechinaAll, getBZAll, getFaultTypeAll, getGroupUser, getFaultCodeBytType} from "@/api/config";

    export default {
        components: {
            importBtn
        },
        data() {
            return {
                nameOrCode: "",
                departmentId: "1",
                page: {
                    pageSize: 20,
                    pagerCount: 5,
                    total: 10,
                    pageCurrent: 1
                },
                startDate: null,
                endDate: null,
                disabled: true,
                faultType: "",
                FaultTypeAllList: [],
                faultCode: "",
                status: null,
                faultCodeList: [],
                tableData: [],
                tableOption: {
                    delBtn: true,
                    menuWidth: 200,
                    selection: true,
                    tip: false,
                    columnBtn: false,
                    labelWidth: 150,
                    dialogFullscreen: true,
                    addBtnText: this.$t('ADD'),
                    delBtnText: this.$t('DELETE'),
                    editBtnText: this.$t('Edit'),
                    emptyText: this.$t('No Data'),
                    menuTitle: this.$t('OPERATION'),
                    column: [{
                        label: this.$t('SELECT DEVICE'),
                        dicData: [],
                        hide: true,
                        type: "select",
                        prop: "machineId",
                        props: {
                            label: "name",
                            value: "id"
                        },
                        rules: [{
                            required: true,
                            message: this.$t('PLEASE ENTER THE DEVICE NAME'),
                            trigger: "blur"
                        }]
                    },
                        {
                            label: this.$t('FAULT ORDER NO'),
                            width: "120",
                            addDisplay: false,
                            editDisplay: false,
                            prop: "id"
                        },
                        {
                            label: this.$t('FAULT TEAM'),
                            dicData: [],
                            type: "select",
                            prop: "departmentId",
                            props: {
                                label: "name",
                                value: "id"
                            },
                            rules: [{
                                required: true,
                                message: this.$t('PLEASE ENTER TEAM NAME'),
                                trigger: "blur"
                            }],
                            formatter: function (row) {
                                return row.departmentName;
                            }
                        },
                        {
                            label: this.$t('EQUIPMENT NAME'),
                            display: false,
                            prop: "machineName",
                            rules: [{
                                required: true,
                                message: this.$t('PLEASE ENTER THE DEVICE NAME'),
                                trigger: "blur"
                            }]
                        },
                        {
                            label: this.$t('EQUIPMENT CODE'),
                            display: false,
                            prop: "machineCode"
                        },
                        {
                            label: this.$t('FAULT TYPE'),
                            dicData: [],
                            type: "select",
                            prop: "type",
                            props: {
                                label: "name",
                                value: "id"
                            },
                            change: (value) => {
                                console.log(value);
                                getFaultCodeBytType(value.value).then(res => {
                                    console.log(res);
                                    this.tableOption.column[6].dicData = res.data.data;
                                })
                            },
                            rules: [{
                                required: true,
                                message: this.$t('PLEASE ENTER FAULT TYPE'),
                                trigger: "blur"
                            }]
                        },
                        {
                            label: this.$t('FAULT RECORD NO'),
                            dicData: [],
                            type: "select",
                            prop: "faultInformationId",
                            props: {
                                label: "code",
                                value: "id"
                            },
                            formatter: function (row) {
                                return row.faultInformationCode;
                            },
                            rules: [{
                                required: true,
                                message: this.$t('PLEASE ENTER THE DEVICE CODE'),
                                trigger: "blur"
                            }]
                        },
                        {

                            label: this.$t('FAULT REMARK'),
                            prop: "remarks"
                        },
                        {
                            label: this.$t('FAULT STARTING TIME'),
                            width: "130",
                            type: "datetime",
                            rules: [{
                                required: true,
                                message: this.$t('PLEASE ENTER THE START TIME'),
                                trigger: "blur"
                            }],
                            valueFormat: "yyyy-MM-dd HH:mm:ss",
                            prop: "faultBeginTime"
                        },
                        {
                            label: this.$t('TROUBLESHOOTER'),
                            dicData: [],
                            type: "select",
                            filterable: true,
                            prop: "disposalUserid",
                            props: {
                                label: "name",
                                value: "userId"
                            },
                            formatter: function (row) {
                                return row.disposalUserName;
                            },
                            rules: [{
                                required: true,
                                message: this.$t('PLEASE SELECT TROUBLESHOOTER'),
                                trigger: "blur"
                            }]
                        },
                        {
                            label: this.$t('STATE'),
                            type: "select",
                            prop: "isFinish",
                            dicData: [{
                                label: this.$t('FOR DISPOSAL'),
                                value: 0
                            }, {
                                label: this.$t('TO BE CONFIRMED'),
                                value: 1
                            }, {
                                label: this.$t('HAVE BEEN CONFIRMED'),
                                value: 2
                            }],
                            rules: [{
                                required: true,
                                message: this.$t('PLEASE SELECT WHETHER THE DISPOSAL IS COMPLETED'),
                                trigger: "blur"
                            }]
                        },
                        // {
                        //     label: this.$t('CREATTIME'),
                        //     width: 100,
                        //     addDisplay: false,
                        //     editDisplay: false,
                        //     prop: "createTime"
                        // },
                        {
                            label: this.$t('CREATE USER'),
                            addDisplay: false,
                            editDisplay: false,
                            prop: "createUsersName"
                        },
                        // {
                        //     label: this.$t('PROCESSING COMPLETION CONFIRMATION TIME'),
                        //     valueFormat: "yyyy-MM-dd HH:mm:ss",
                        //     width: 110,
                        //     type: "datetime",
                        //     prop: "confirmTime"
                        // },
                        {
                            label: this.$t('START TIME OF DISPOSAL'),
                            width: "130",
                            type: "datetime",
                            valueFormat: "yyyy-MM-dd HH:mm:ss",
                            prop: "beginTime"
                        },
                        {
                            label: this.$t('DISPOSAL COMPLETION TIME'),
                            width: "130",
                            type: "datetime",
                            valueFormat: "yyyy-MM-dd HH:mm:ss",
                            prop: "endTime"
                        },
                        {
                            label: this.$t('CONFIRM TIME'),
                            width: "130",
                            type: "datetime",
                            valueFormat: "yyyy-MM-dd HH:mm:ss",
                            prop: "confirmTime"
                        },
                        {
                            label: this.$t('CONFIRM THE PEOPLE'),
                            dicData: [],
                            type: "select",
                            filterable: true,
                            prop: "confirmUserid",
                            props: {
                                label: "name",
                                value: "userId"
                            },
                            formatter: function (row) {
                                return row.confirmUsersName;
                            }
                        }]
                }
            }
        },
        created() {
            //所有的设备
            getMechinaAll().then(res => {
                this.tableOption.column[0].dicData = res.data.data;
            });
            //所有的班组
            getBZAll().then(res => {
                this.tableOption.column[2].dicData = res.data.data;
            });
            //所有故障类型
            getFaultTypeAll().then(res => {
                this.FaultTypeAllList = res.data.data
                this.tableOption.column[5].dicData = res.data.data;
            });
            //班组下的人员
            getGroupUser().then(res => {
                this.tableOption.column[9].dicData = res.data.data;
                this.tableOption.column[15].dicData = res.data.data;
            });
            this.list();
        },
        methods: {
            getFaultTypeId(vel) {
                console.log('vel', vel);
                getFaultCodeBytType(vel).then(res => {
                    console.log(res);
                    this.faultCodeList = res.data.data
                })
            },
            currentChange(pageCurrent) {
                this.page.pageCurrent = pageCurrent;
                this.list();
            },
            sizeChange(pageSize) {
                this.page.pageCurrent = 1;
                this.page.pageSize = pageSize;
                this.list();
            },
            /*        refreshData() {
                      this.list();
                    },*/
            handleDel(row) {
                this.$confirm(this.$t('IsDel'), this.$t('Tips'), {
                    confirmButtonText: this.$t('Yes'),
                    cancelButtonText: this.$t('Cancel'),
                    type: 'warning'
                }).then(() => {
                    remove(row.id).then((res) => {
                        if (res.data.code == "0000") {
                            this.$message.success("删除成功")
                        }
                        this.list();
                    })
                })
            },
            handleUpdate(row, index, done) {
                console.log(row);
                edit(row).then((res) => {
                    console.log(res);
                    if (res.data.code == "0000") {
                        this.$message.success("修改成功")
                    }
                    this.list();
                    done();
                })
            },
            handleSave(row, done) {
                console.log(row)
                add(row).then((res) => {
                    if (res.data.code == "0000") {
                        this.$message.success("新增成功")
                    }
                    this.list();
                    done();
                })
            },
            change(row) {
                changeStatus(row)
            },
            selectionChange(list) {
                console.log(list);
                list != "" ? this.disabled = false : this.disabled = true;
            },
            refreshChange() {
                this.list();
            },
            list() {
                this.tableData = [];
                list(this.page.pageCurrent, this.page.pageSize, this.faultType, this.faultCode, this.nameOrCode, this.status, this.startDate, this.endDate).then(res => {
                    console.log(res);
                    if (res.data.data == null) {
                        this.tableData = [];
                        this.page.total = 0;
                    }else {
                        this.page.total = res.data.data.total;
                        this.tableData = res.data.data.items;
                        this.tableData.forEach(v => {
                            v.status = v.status;
                        })
                    }


                })
            },
            envText: function () {
                return this.env
            }
        }
    }
</script>
<style scoped>
    .el-select-dropdown .el-scrollbar .el-scrollbar__view .el-select-dropdown__item {
        padding-left: 10px;
    }
</style>